// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables/colors";
@import "variables/general";
@import "variables/typhography";
@import "variables/font-styles";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// CSS Arquetipo
@import "engine";
// CSS Master page (layouts/app)
@import "applayout";
@import "controls";
@import "sections";
@import "others";
@import "datepicker";
@import "media_queries";
// Importar más css aquí
// @import "custom"

// Fonts: Nunito / Inter / Opens Sans / Roboto
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* Fonts: Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800;900&display=swap');