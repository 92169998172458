body {
    font-family: "Inter", sans-serif;

    header {
        .navbar {
            padding: 0.75rem 1rem;
        }

        .submenu {
            display: flex;
            align-items: center;
            justify-content: space-between;

            // padding: 0.75rem 1rem;
            // border-top: 1px solid $st-gray_08;
            a.back {
                color: white;
                font-size: $fs-15;
                font-weight: 500;
                background-color: $st-purple-01;
                padding: 0.35rem 0.75rem;
                border-radius: 5px;

                &:hover {
                    background-color: $st-purple-01-ho;
                }
            }

            ul {
                margin: 0 0 0 auto;
                padding: 0;

                li {
                    list-style: none;

                    a {
                        color: $st-green_02;
                        font-size: $fs-16;
                        font-weight: 500;

                        &:hover {
                            color: $st-guinda_03;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    main {
        background-color: white;

        #bandeja {
            .encabezado {
                color: $st-gray-01;
                font-size: $fs-30;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -0.03em;
                background-color: $st-purple-04;
                border-radius: 15px;
                padding: 1rem 2rem;
            }

            .sidebar {
                background-color: $st-gray-03;
                box-shadow: 0px 16px 85px rgba(0, 146, 135, 0.0395542);
                border-radius: 10px 10px 10px 0px;
                padding: 1.5rem 2rem;

                form {

                    .st-label,
                    .st-input {
                        display: block;
                        font-size: $fs-15;
                        font-weight: 600;
                    }
                }
            }

            table {
                font-family: 'Montserrat', sans-serif;
                border-collapse: collapse;

                thead {
                    th {
                        width: calc(100% / 6);
                        color: $st-gray-02;
                        font-size: 0.875rem;
                        font-weight: 600;
                        line-height: normal;
                        text-align: left;
                        background-color: $st-purple-04;
                        border-top: none;
                        padding: 1.5%;
                        border-bottom: none;

                        &:first-child {
                            border-top-left-radius: 15px;
                        }

                        &:last-child {
                            border-top-right-radius: 15px;
                        }
                    }
                }

                tbody {
                    tr {
                        background-color: $st-gray-04;

                        td {
                            width: calc(100% / 6);
                            color: black;
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: normal;
                            text-align: left;
                            padding: 1.5%;
                            border-top: 1px solid $st-purple-03;

                            .content-cut {
                                overflow: hidden;
                                // white-space: nowrap;
                                text-overflow: ellipsis;
                                max-width: 200px;
                                width: 100%;
                                text-align: left;
                            }

                            .dropdown {
                                .dropdown-options {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: white;
                                    font-size: 0.825rem;
                                    font-weight: 600;
                                    line-height: 18px;
                                    background-color: $st-purple-01;
                                    padding: 0.3rem 0.8rem;
                                    border-radius: 16px;

                                    &:hover {
                                        background-color: $st-purple-01-ho;
                                        text-decoration: none;
                                    }
                                }

                                .dropdown-menu {
                                    .dropdown-item {
                                        padding: 0;

                                        .dropdown-action {
                                            display: block;
                                            width: 100%;
                                            clear: both;
                                            color: #212529;
                                            font-size: 0.775rem;
                                            font-weight: 400;
                                            text-align: inherit;
                                            text-decoration: none;
                                            white-space: nowrap;
                                            background-color: transparent;
                                            border: 0;
                                            padding: 0.25rem 1.5rem;

                                            img {
                                                width: 20px;
                                                margin: 0 0.35rem 0 0;
                                                filter: invert(100%);
                                            }

                                            &:hover {
                                                color: white;

                                                img {
                                                    filter: invert(0);
                                                }
                                            }
                                        }

                                    }
                                }

                            }

                        }
                    }
                }
            }

            ul.pagination {
                min-width: 100%;
                justify-content: center;

                li {
                    &.page-item {
                        padding: 0.15rem;

                        &:first-child,
                        &:last-child {
                            font-size: 1rem;
                            color: black !important;
                            font-weight: 800;

                            .page-link {
                                padding-top: 0.4rem;
                            }
                        }

                        &.active {
                            .page-link {
                                z-index: 3;
                                color: white !important;
                                background-color: $st-purple-01;
                            }
                        }

                        &.disabled {
                            .page-link {
                                color: $st-gray_08 !important;
                                font-weight: normal;
                            }
                        }
                    }
                }

                .page-link {
                    color: black;
                    line-height: normal;
                    border: none;
                    border-radius: 5px;
                }
            }
        }

        .form {
            .encabezado {
                color: $st-gray-01;
                font-size: $fs-28;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -0.03em;
                background-color: $st-purple-04;
                border-radius: 15px;
                padding: 1rem 2rem;
            }

            form {
                background-color: white;

                .st-label,
                .st-input {
                    font-size: $fs-18;
                    font-weight: 500;
                }

                .container-radio {
                    display: inline-flex;
                    align-items: center;

                    label {
                        margin: 0 0.5rem;
                    }

                    input {
                        margin: 0;
                    }
                }

                .cont-dp {
                    button.ui-datepicker-trigger {
                        padding: 0.8rem 0.7rem 0.7rem;
                    }
                }

                select.st-input {
                    background-position: calc(100% - 19px) calc(1em + 1px), calc(100% - 13px) calc(1em + 1px), 100.5% 0;
                    background-size: 7px 7px, 7px 7px, 2.3em 2.5em;
                }
            }

            .actions {
                text-align: center;
                background: rgba(152, 152, 154, 0.25);
                border-radius: 0px 0px 10px 10px;
                padding: 1rem 2rem;
            }
        }

        #successful {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 60vh;

            .message {
                text-align: center;
                letter-spacing: -0.03em;
                line-height: normal;
                background-color: #fff;
                border-radius: 40px;
                padding: 80px;

                .title {
                    font-size: $fs-32;
                    font-weight: 700;
                    color: #000;
                }

                .subtitle {
                    font-size: $fs-26;
                    font-weight: 600;
                }

                hr {
                    width: 72px;
                    border-top: 4px solid $st-purple-01;
                }

                span {
                    color: $rositaa
                }
            }
        }
    }
}

.visitaok {
    background-color: $foondo !important;
}