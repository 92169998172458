// Botones
button, .button{
	display: inline-block;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.035rem;
    line-height: normal;
    padding: 0.6rem 1.3rem;
    border: none;
    border-radius: 5px;

	&.sombra{
	-webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.28);
	box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.28);		
	}

	&.verde{
		color:$white-two;
		background-color: $verde01;
	}
	&.verde:disabled{
		color:$white-two;
		background-color: $verde04;
	}

	&.gris{
		color:$white-two;
		background-color: $gris02;
	}
	&.gris:disabled{
		color:$white-two;
		background-color: $gris03;
	}
	
	&.amarillo{
		color:$gris01;
		background-color: $amarillo01;
	}
	&.amarillo:disabled{
		color:$gris03;
		background-color: $amarillo02;
	}
	
	&.rojo{
		color:$white-two;
		background-color: $rojo01;
	}
	&.rojo:disabled{
		color:$white-two;
		background-color: $rojo02;
	}
	
	&.dorado{
		color:$white-two;
		background-color: $dorado01;
	}
	&.dorado:disabled{
		color:$white-two;
		background-color: $dorado04;
	}
}


// Controles
textarea{
	resize: none;
}

.form-control-e {
  border-radius: 0.5rem;
  border: 0.0625rem solid $light-blue-grey;
  color: $charcoal-grey-four;
  font-size: 0.85rem;
  background-color: transparent;
}

.form-control-e:focus {
  color: $verde03;
  background-color: $white-two;
  border-color: $teal-two !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 146, 136, 0.25) !important;
}

.form-label-a {
  color: $teal-two;
  font-weight: 700;
  font-size: 12px;
  font-weight: bold;
}