// Forms

form {
    .st-label {
        width: 100%;
        color: $st-gray_09;
        line-height: normal;

        span {
            color: $st-purple-01;
            font-weight: 800;
        }
    }

    .st-input {
        width: 100%;
        color: $st-gray_09;
        line-height: normal;
        padding: 0.5rem 1rem;
        border: 1px solid #a0aec0;
        border-radius: 8px;
    }

    select.st-input {
        display: inline-block;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: white;
        background-image: linear-gradient(45deg, transparent 50%, white 50%),
            linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, $st-purple-02, $st-purple-02);
        background-position: calc(100% - 17.5px) calc(1em + 0px), calc(100% - 10.5px) calc(1em + 0px), 100.5% 0;
        background-size: 7px 7px, 7px 7px, 2.5em 2.4em;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
            background-image: linear-gradient(45deg, transparent 50%, white 50%),
                linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, $st-purple-02-ho, $st-purple-02-ho);
        }
    }

    .event-partial {
        width: 100%;
    }

    /* Input: Checkbox / Radio */
    .cont-input {
        display: block;
        position: relative;
        width: fit-content;
        color: $st-gray_06;
        font-size: $fs-16;
        line-height: 20px;
        margin: 0;
        padding: 0.15rem 0 0.15rem 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
                &~.checkmark {
                    background-color: $st-purple-02;
                    border-color: $st-purple-02-ho;

                    &:after {
                        display: block;
                    }
                }
            }
        }

        .checkbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 2px solid $st-gray_05;
            border-radius: 5px;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 9.5px;
                top: 4px;
                width: 6px;
                height: 13px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }

        .radio {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $st-gray_07;
            border-radius: 50%;

            &:after {
                content: "";
                position: absolute;
                display: none;
                top: 7px;
                left: 8px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: white;
            }
        }

        &.pe-none {
            color: $st-gray_07;
            font-style: italic;
        }

        &:hover {
            color: $st-purple-02-ho;

            .checkmark {
                border-color: $st-purple-02-ho;
            }
        }
    }
}

// Buttons

.st-btn {
    display: inline-block;
    height: fit-content;
    font-size: $fs-14;
    font-weight: 600;
    letter-spacing: 0.035rem;
    line-height: normal;
    padding: 0.85rem 1rem;
    border: none;
    border-radius: 10px;

    &.add {
        font-size: $fs-15;
        font-weight: 800;
        line-height: 18px;
        padding: 0.6rem 0.7rem;
        border-radius: 15px;
    }

    &.submit {
        font-size: $fs-17;
        line-height: 24px;
        letter-spacing: -0.01em;
        padding: 0.6rem 1rem;

        img {
            margin-top: -3px;
        }
    }

    &.white {
        color: $st-purple-01;
        background-color: white;
        border: 2px solid $st-purple-01;

        img {
            background-color: $st-purple-01;
        }

        &:hover {
            color: $st-purple-01-ho;
            border-color: $st-purple-01-ho;
        }
    }

    &.purple {
        color: white;
        background-color: $st-purple-01;

        &:hover {
            background-color: $st-purple-01-ho;
        }

        &:disabled {
            background-color: #CCC;
        }
    }

    &.green {
        color: white;
        background-color: $st-green_03;

        &:hover {
            background-color: $st-green_04;
        }
    }

    &.guinda {
        color: white;
        background-color: $st-guinda_02;

        &:hover {
            background-color: $st-guinda_03;
        }
    }

    &.gray {
        color: white;
        background-color: $st-gray_09;

        &:hover {
            background-color: $st-gray_05;
        }
    }

    &.gold {
        color: white;
        background-color: $st-gold_01;

        &:hover {
            background-color: $st-purple-02-ho;
        }

        &:disabled {
            background-color: #CCC;
        }
    }

    // &.red {
    //     color: white !important;
    //     background-color: $st-red_03;
    //     &:hover {
    //         background-color: $st-red_04;
    //     }
    // }
    // &.gray {
    //     color: $st-black_01;
    //     background-color: $st-gray_09;
    //     &:hover {
    //         background-color: $st-gray_05;
    //     }
    // }
    // &.white {
    //     color: $st-black_01;
    //     background-color: white;
    //     &:hover {
    //         color: $st-red_03;
    //         background-color: $st-red_01;
    //     }
    // }
    // &:hover {
    //     text-decoration: none;
    // }
    // &:focus {
    //     outline: none;
    // }
    // &:disabled {
    //     color: $st-gray_05;
    //     background-color: $st-gray_07;
    //     pointer-events: none !important;
    // }
    // &.lens {
    //     font-size: 0.95rem;
    //     padding: 0.4rem 0.8rem;
    // }
    &:hover {
        text-decoration: none;
    }

    img {
        margin-right: 0.25rem;
        transform: scale(0.75);
    }
}

.pointer {
    cursor: pointer;
}