header {
    border-bottom: 5px solid $gray-01;
    padding: 0em 0rem 0rem;
    nav {
        margin: 0 auto;
        max-width: 6000px;
    }
}

footer {
    background-color: $st-green_03;
    h5 {
        color: #fff;
        font-weight: 700;
        font-size: $fs-20;
        margin: 0 0 1rem;
    }
    p {
        color: #fff;
        font-size: $fs-15;
        font-weight: 400;
        line-height: normal;
        a {
            font-weight: 600;
        }
    }
    li {
        list-style: none;
        margin: 0 0 0.5rem;
    }
    a {
        color: #fff;
        font-size: $fs-14;
        font-weight: 400;
        line-height: 20px;
        text-decoration: underline;
        &:hover {
            color: $dorado03;
        }
    }
    .greca-footer {
        max-height: 36px;
        min-height: 36px;
        background-image: url("../images/greca.png");
        background-repeat: repeat-x;
    }
}

// Estilos
.app-title {
    height: 34.6px;
    font-family: "Gotham Bold" !important;
    font-size: 9px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $bluish-grey;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 5px;
}

@media only screen and (max-width: 585px) {
    .auto-hide {
        display: none;
    }
}

.linea {
    width: 0.3px !important;
    height: 34.6px;
    border-right: solid 2px $bluish-grey;
    margin: 5px !important;
}

.brand-logo-b {
    max-width: 100%;
    height: auto;
}

.tx-crea-cuenta {
    width: 100%;
    font-family: "Metropolis";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brownish-grey;
}

.tx-crea-cuenta a {
    color: $st-purple-01;
}

#nav-secciones {
    background-color: $st-gray-04;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nb-usuario {
    color: $st-purple-01;
    width: 73px;
    height: 20px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: right;
    text-transform: capitalize !important;
}

.user-ico {
    width: 20px;
    padding-right: 5px;
    filter: saturate(0%);
}

.tp-user {
    font-family: Metropolis;
    font-size: 11px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.18px;
    text-align: right;
    color: $pine-green;
    padding-right: 30px;
}

.llave-logo {
    max-width: 183px;
    height: auto;
}
