@media only screen and (min-width: 300px) {
    .ui-datepicker {
        transform: scale(1.75) translateX(22%);
    }
}

@media only screen and (min-width: 345px) {
    .ui-datepicker {
        transform: scale(2) translateX(25%);
    }
}

@media only screen and (min-width: 400px) {
    .ui-datepicker {
        transform: scale(2.4) translateX(28%);
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

@media (max-width: 720px) {
    html {
        body {
            main {
                button {
                    &.ui-datepicker-trigger {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    #form {
        .row {
            width: 70%;
        }
    }

    #form-qr {
        .row {
            width: 75%;
        }
    }
}

@media only screen and (min-width: 900px) {
    .ui-datepicker {
        transform: scale(1.5) translate(20%, 20%);
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
