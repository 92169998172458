$st-gray-01: #535353;
$st-gray-02: #525154;
$st-gray-03: #F2F2F4;
$st-gray-04: #F8F8F8;

$st-purple-01: #9482A3; // Dropdown
$st-purple-01-ho: #8a769b;

$st-purple-02: #CAC4DB; // Datepicker, Select
$st-purple-02-ho: #b2a9ca;

$st-purple-03: #D6C8D7;
$st-purple-03-ho: #c3acc5;

$st-purple-04: #F1EEF7;

$st-guinda_01: #9f2241;
$st-guinda_02: #691c32;
$st-guinda_03: #551024; // Hover
$st-guinda_04: #62121F;

$st-green_01: #235b4e;
$st-green_02: #104c42;
$st-green_03: #10312b;
$st-green_04: #081f1a; // Hover

$st-gray_07: #e2e8f0;
$st-gray_08: #a9a9a9;
$st-gray_09: #6f7271;
$st-gray_05: #475058;
$st-gray_06: #2d3748;

$st-gold_01: #bc955c;
$st-gold_02: #b18147;
$st-gold_03: #a06e30;

$st-yellow_01: #fff3cd;

$st-red_01: #cc0d0d;

$st-white_01: #f8fafc;
$st-white_02: #f2f7f6;

// Guindas 4T
$guinda01: #75293d;
$guinda02: #691c32;
$guinda03: #9f2241;
$guinda04: #a17882;

// Verdes
$verde01: #2a5d50;
$verde02: #235b4e;
$verde03: #1b5c4f;
$verde04: #c1d6d3;
$verde05: #00312d;

// Dorados
$dorado01: #ab8d5d;
$dorado02: #d0b391;
$dorado03: #bc955c;
$dorado04: #ece0cb;
$dorado05: #c0af94;

// Grises
$gris01: #1a1a1a;
$gris02: #495057;
$gris03: rgb(201, 201, 201);

// Blancos
$white01: #f4f6f8;
$white02: #f8fafc;

// Amarillos
$amarillo01: rgb(221, 169, 0);
$amarillo02: rgb(255, 240, 173);

// Rojos
$rojo01: firebrick;
$rojo02: rgb(231, 172, 172);

// Otro fondo
$foondo: #E0E0E0;

// Otro rosa
$rositaa: #B1105C;

// Bostrap
$white-20: #d8d8d8;
$grassy-green: #31b700;
$grassy-green-two: #30b702;
$teal: #009f98;
$fresh-green-89: #76d366;
$dark-green: #145046;
$charcoal-grey: #4a4e4e;
$black-30: #00004c;
$dark-blue-green: #004c3f;
$white-gray: #f5f5f5;
$brown-grey: #979797;
$silver: #c0c5c1;
$true-green: #1bb600;
$pine: #32685a;
$white-two: #ffffff;
$black-79: #0000ca;
$shamrock: #00b140;
$dark-grey-blue: #2c2762;
$black: #212121;
$brown-grey-two: #acacac;
$greyish-teal: #7f9494;
$teal-two: #009288;
$very-light-pink: #f0f0f0;
$pine-green: #0f4c42;
$slate-grey: #5d5d5e;
$dark-grey: #262629;
$very-light-pink-two: #c9c9c9;
$brownish-grey: #5d5d5d;
$very-light-pink-three: #e7e7e7;
$brownish-grey-two: #5f5f5f;
$very-light-pink-four: #d4d4d4;
$white-four: #f6f6f6;
$red: #ff0000;
$duck-egg-blue: #dce0e0;
$cloudy-blue: #c1c7c8;
$charcoal-grey-two: #414849;
$very-light-pink-five: #ececec;
$gunmetal: #596364;
$very-light-pink-six: #dddddd;
$kelly-green: #00a82d;
$very-light-pink-seven: #d5d5d5;
$greyish-brown: #545454;
$brown-grey-three: #7b7b7b;
$bluish-grey: #898d8d;
$greyish-brown-two: #4a4a4a;
$brown-grey-four: #9b9b9b;
$orangey-yellow: #f5a623;
$greyish-brown-three: #535353;
$very-light-pink-eight: #dcdcdc;
$brown-grey-five: #a3a3a3;
$charcoal-grey-three: #4c5050;
$warm-grey: #8b8888;
$white: #ffffff;
$brown-grey-six: #7e7a7a;
$greyish-brown-four: #484747;
$black-28: #000046;
$black-three: #010101;
$brown-grey-seven: #7d7d7d;
$very-light-pink-nine: #efeeee;
$brownish-grey-three: #676767;
$brownish-grey-four: #737373;
$black-two: #2d2d2d;
$very-light-pink-ten: #dedede;
$very-light-pink-eleven: #d3d3d3;
$brown-grey-eight: #9f9f9f;
$brownish-grey-five: #747474;
$brownish-grey-six: #767676;
$black-all: #000000;
$gray-form: #ededed;
$gray-form-h3: #8e9393;
$grey-h3-title: #848484;
$charcoal-grey-four: #383b41;
$red-error-btstrp: #dc3545;
$grey-light: #8f8f8f;
$light-grey-blue: #a5adbb;
$light-blue-grey: #c1c7d0;
$yellow-chrome: #fca800;
$coral: #ff784f;
$old-mauvee: #65334d;
$blue-lazuli: #2274a5;
$green-apple: #6cc551;
$gray-line: #d7d7d7;
$gray-input: #ebecf0;
$red-comment: #f65545;
$green-pine: #008374;
$atlantis: #97e545;
$charcoal: #4a4949;
$blue: #3490dc;
$blue-two: #0091ff;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$red-two: #c71e1e;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$green-two: #6dd400;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray-01: #f0f0f0;
$gray-02: #6d7278;
$gray-04: #b9b9b9;
$gray-05: #969ca4;
$green-01: #00b140;
$bastille: #2e2e32;