// Font size
$fs-9_3: 0.58125rem;
$fs-10: 0.625rem;
$fs-10_5: 0.65625rem;
$fs-11: 0.6875rem;
$fs-12: 0.75rem;
$fs-13: 0.8125rem;
$fs-14: 0.875rem;
$fs-15: 0.9375rem;
$fs-16: 1rem;
$fs-17: 1.063rem;
$fs-18: 1.125rem;
$fs-19: 1.188rem;
$fs-20: 1.25rem;
$fs-21: 1.3125rem;
$fs-22: 1.375rem;
$fs-23: 1.438rem;
$fs-24: 1.5rem;
$fs-25: 1.563rem;
$fs-26: 1.625rem;
$fs-27: 1.688rem;
$fs-28: 1.75rem;
$fs-29: 1.813rem;
$fs-30: 1.875rem;
$fs-31: 1.938rem;
$fs-32: 2rem;
$fs-33: 2.063rem;
$fs-34: 2.125rem;
$fs-35: 2.188rem;
$fs-36: 2.25rem;
$fs-37: 2.313rem;
$fs-38: 2.375rem;
$fs-39: 2.438rem;
$fs-40: 2.5rem;
$fs-41: 2.563rem;
$fs-42: 2.625rem;
$fs-43: 2.688rem;
$fs-44: 2.75rem;
$fs-45: 2.813rem;
$fs-46: 2.875rem;
$fs-47: 2.938rem;
$fs-48: 3rem;
$fs-49: 3.063rem;
$fs-50: 3.125rem;
$fs-56: 3.5rem;

// Line Height
$lh-12: 12px;
$lh-15: 15px;
$lh-16: 1rem;
$lh-19: 19px;
$lh-20: 20px;
$lh-21: 21px;
$lh-22: 22px;
$lh-23: 23px;
$lh-24: 1.5rem;
$lh-25: 25px;
$lh-26: 26px;
$lh-27: 27px;
$lh-28: 28px;
$lh-29: 29px;
$lh-30: 30px;
$lh-32: 2rem;

// Font weight
$fw-extra-light: 100;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;

// Letter spacing
$ls-0: 0;
$ls-01: 0.1px;
$ls-02: 0.2px;
$ls-03: 0.3px;
$ls-04: 0.4px;
$ls-05: 0.5px;
$ls-1: 1px;